var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card gutter-b"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-md-3",attrs:{"id":"input-group-1","label":"Reference no.","label-for":"input-1","description":"For custom referencing if needed"}},[_c('b-form-input',{attrs:{"id":"input-1","type":"text","name":"reference_no"},model:{value:(_vm.job.reference_no),callback:function ($$v) {_vm.$set(_vm.job, "reference_no", $$v)},expression:"job.reference_no"}})],1)],1)])])])]),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"card-title"},[_c('h3',[_vm._v("Trips")])]),(true)?_c('div',{staticClass:"card-toolbar"},[_c('b-button',{staticClass:"mb-2",attrs:{"size":"lg","variant":"white"},on:{"click":_vm.addTrip}},[_c('b-icon',{attrs:{"icon":"plus-circle","aria-label":"Add"}})],1)],1):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('div',_vm._l((_vm.job.trips),function(trip,index){return _c('add-trip-form',{key:trip._id,attrs:{"trip":trip,"config":_vm.config,"index":index},on:{"open-address-book":function($event){return _vm.openAddressBook(index)}}})}),1)])]),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0"},[_c('div',{staticClass:"card-title"},[_c('h3',[_vm._v("Items")])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"col-md-4",attrs:{"rules":"required","name":"Item Weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-21","label":"Weight(kg)","label-for":"input-21","description":"Please enter weight in Kilograms"}},[_c('b-form-input',{attrs:{"id":"input-21","type":"text","name":"item_weight","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.job.item_weight),callback:function ($$v) {_vm.$set(_vm.job, "item_weight", $$v)},expression:"job.item_weight"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"col-md-4",attrs:{"rules":"required","name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"input-group-22","label":"Number of item (Document / Bag / Carton)","label-for":"input-22","description":"Please enter the amount in packaged form (Document / Bag / Carton)"}},[_c('b-form-input',{attrs:{"id":"input-21","type":"text","name":"number_of_items","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.job.number_of_items),callback:function ($$v) {_vm.$set(_vm.job, "number_of_items", $$v)},expression:"job.number_of_items"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])]),_c('submit-button',{attrs:{"loading":_vm.loading,"text":"Submit"}})],1)]}}])}),_c('b-modal',{attrs:{"id":"bv-modal-address","hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Address book ")]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('v-client-table',{attrs:{"columns":_vm.columns,"options":_vm.options},on:{"row-click":_vm.addressBookClicked},model:{value:(_vm.addressbooks),callback:function ($$v) {_vm.addressbooks=$$v},expression:"addressbooks"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }